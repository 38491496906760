import { Injectable, inject } from '@angular/core';
import { LoggerFactoryToken } from '@services/logger.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getUserPreferences,
  updateSecondaryEmails,
  setUserPreferences,
  setSecondaryEmailsSuccess,
  getOrganizations,
  setOrganizations,
} from './user.actions';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';
import { ApiEndPoints, DataService } from '@services/data.service';
import { CommandRequestBuilderService } from 'src/app/services/command-request-builder.service';
import { UserEvents } from 'src/app/services/command-request.service';
import { Action } from '@ngrx/store';
import { displayMessage, emptyAction, userLoginComplete } from '@ra-state';

@Injectable({
  providedIn: 'root',
})
export class UserEffects {
  createSpUser$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(userLoginComplete),
      switchMap(() => {
        const commandRequest = this.commandRequestBuilderService
          .new(`/SupportUsers`, 'POST', UserEvents.SpUserCreated)
          .withBody({})
          .withWaitOn201Created();
        return this.dataService.commandRequest$(commandRequest);
      }),
      map(() => emptyAction()),
    );
  });

  userPreferences$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getUserPreferences),
      switchMap((payload) => {
        return this.dataService.getUserPreferences$(payload.userId);
      }),
      map((response) => {
        return setUserPreferences({ userState: response });
      }),
    );
  });

  secondaryEmails$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(updateSecondaryEmails),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new(
            `${ApiEndPoints.CustomerSupport}${payload.userId}/secondaryEmails`,
            'PUT',
            UserEvents.UserSecondaryEmailsSet,
          )
          .withBody(payload.secondaryEmails)
          .asAssistedRequest()
          .withWaitOn200Ok();

        return combineLatest([this.dataService.commandRequest$(commandRequest), of(payload.secondaryEmails)]);
      }),
      switchMap(([_res, secondaryEmails]) => {
        const actions: Action[] = [];
        const displayMsg = displayMessage({
          payload: {
            message: 'The secondary entitlement account was updated successfully for user',
            type: 'Success',
          },
        });
        actions.push(displayMsg);
        actions.push(setSecondaryEmailsSuccess({ secondaryEmails }));
        return actions;
      }),
    );
  });

  logger = inject(LoggerFactoryToken)(UserEffects);

  organizations$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getOrganizations),
      switchMap((payload) => {
        return this.dataService.getUserOrganisations$(payload.userId);
      }),
      map((response) => {
        return setOrganizations({ organizations: response });
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private commandRequestBuilderService: CommandRequestBuilderService,
  ) {
    this.logger.log('control page effects loads');
  }
}
