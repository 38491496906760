import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { from, fromEvent, Observable, timer } from 'rxjs';
import { switchMap, mergeMap, tap, take } from 'rxjs/operators';
import { LoggerFactoryToken } from './../../core/services/logger.service';
import {
  checkUserActive,
  setUserActivityRecorded,
  recordUserActivity,
  SessionManagementFacade,
  setUserActive,
  userLoginComplete,
} from '@ra-state';
import { CommonConstants } from '@app/common-constants';
@Injectable({
  providedIn: 'root',
})
export class SessionManagementEffects {
  getTimer: () => Observable<number>;

  loginCompleted$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(userLoginComplete),
      tap(() => this.generateTimer()),
      switchMap(() => {
        return [recordUserActivity(), checkUserActive()];
      }),
    );
  });

  recordUserActivity$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(recordUserActivity),
      switchMap(() => from(['keydown', 'click', 'wheel', 'mousemove'])),
      mergeMap((event) => fromEvent(document, event).pipe(take(1))),
      concatLatestFrom(() => [this.sessionManagementFacade.userActive$]),
      switchMap(() => {
        return [setUserActivityRecorded({ value: true })];
      }),
    );
  });

  defaultTimer = (): Observable<number> => {
    return timer(CommonConstants.userActivityIntervalMillis, CommonConstants.userActivityIntervalMillis);
  };

  generateTimer(getTimer = this.defaultTimer): void {
    this.getTimer = getTimer;
  }

  checkUserActive$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(checkUserActive),
      switchMap(() => this.getTimer()),
      concatLatestFrom(() => [this.sessionManagementFacade.userActivityRecorded$]),
      switchMap(([_, userActive]) => {
        return [setUserActive({ value: Boolean(userActive) }), setUserActivityRecorded({ value: false })];
      }),
    );
  });

  logger = inject(LoggerFactoryToken)(SessionManagementEffects);
  constructor(
    private actions$: Actions,
    private sessionManagementFacade: SessionManagementFacade,
  ) {}
}
