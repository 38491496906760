import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, distinctUntilChanged } from 'rxjs';
import { selectFlags } from './feature-flags.selectors';
import { AppState, FeatureFlag, FlagValue } from '@ra-state';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsFacade {
  flags$ = this.store$.select(selectFlags);

  constructor(private store$: Store<AppState>) {}

  getFlagValue$(flag: FeatureFlag): Observable<FlagValue> {
    return this.flags$.pipe(
      map((flags) => (flags ? flags[flag] : undefined)),
      distinctUntilChanged(),
    );
  }
}
