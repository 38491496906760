import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  cancelReservation,
  createCampaign,
  endCampaign,
  getCampaignDetails,
  getTrialBundles,
  pauseCampaign,
  reserveTrial,
  resumeCampaign,
  updateCampaign,
} from './trials.action';
import { selectCampaignDetails, selectTrialBundles } from './trials.selectors';
import { AppState, CancelTrial, CreateCampaign, ReserveTrial, CampaignUpdateRequest } from '@ra-state';

@Injectable({
  providedIn: 'root',
})
export class TrialsFacade {
  campaignDetails$ = this.store$.select(selectCampaignDetails);
  trialBundles$ = this.store$.select(selectTrialBundles);

  reserveTrial(customerInfo: ReserveTrial): void {
    this.store$.dispatch(reserveTrial({ payload: customerInfo }));
  }

  cancelReservation(reservationData: CancelTrial): void {
    this.store$.dispatch(cancelReservation({ payload: reservationData }));
  }

  createCampaign(campaignPayload: CreateCampaign): void {
    this.store$.dispatch(createCampaign({ payload: campaignPayload }));
  }

  getCampaignDetails(campaignId: string): void {
    this.store$.dispatch(getCampaignDetails({ payload: campaignId }));
  }

  pauseCampaign(campaignId: string): void {
    this.store$.dispatch(pauseCampaign({ campaignId: campaignId }));
  }

  resumeCampaign(campaignId: string): void {
    this.store$.dispatch(resumeCampaign({ campaignId: campaignId }));
  }

  endCampaign(campaignId: string): void {
    this.store$.dispatch(endCampaign({ campaignId: campaignId }));
  }

  getTrialBundles(): void {
    this.store$.dispatch(getTrialBundles());
  }

  updateCampaign(payload: CampaignUpdateRequest): void {
    this.store$.dispatch(updateCampaign({ payload }));
  }

  constructor(private store$: Store<AppState>) {}
}
