import { Action, createReducer, on } from '@ngrx/store';
import { setCampaignDetails, setTrialBundles } from './trials.action';
import { TrialsState, TrialCampaignStatus } from '@ra-state';

export const initialTrialsState: TrialsState = {
  campaignDetails: {
    bundleId: '',
    createdByUser: '',
    createdByUserId: '',
    createdOn: '' as unknown as Date,
    description: '',
    endedByUser: '',
    endedByUserId: '',
    endedOn: '' as unknown as Date,
    id: '',
    name: '',
    partitionId: '',
    pausedByUser: '',
    pausedByUserId: '',
    pausedOn: '' as unknown as Date,
    redeemedTrials: 0,
    reservedTrials: 0,
    status: '' as TrialCampaignStatus,
    trialDurationInDays: '',
    trialRedemptionLimitInDays: 0,
    viewType: '',
    perTrialCredits: 0,
    credits: 0,
    trialsQuantity: 0,
    remainingTrialsQuantity: 0,
  },
  trialBundles: [{
    id: '',
    nameAndDescription: ['', ''],
    creditsAndProductCatalogs: [],
    name: '',
    description: '',
    credits: null,
    products: []
  }]
};

const trialsData = createReducer(
  initialTrialsState,
  on(
    setCampaignDetails,
    (state: TrialsState, { payload }): TrialsState => ({
      ...state,
      campaignDetails: payload,
    }),
  ),
  on(
    setTrialBundles,
    (state: TrialsState, { payload }): TrialsState => ({
      ...state,
      trialBundles: payload,
    }),
  )
);

export function trialsReducer(state: TrialsState, action: Action): any {
  return trialsData(state, action);
}
