import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getTenantUsers,
  setTenantUsers,
  getTenantDetails,
  setTenantDetails,
  getTenantUtilityTokens,
  setTenantUtilityTokens,
  transferOwnership,
  createAdminInvitation,
  getTenantFilteredUsers,
  setTenantFilteredUsers,
} from './tenant.actions';
import { Observable, combineLatest, map, of, switchMap, throwError } from 'rxjs';
import { ApiEndPoints, CustomerSupportEndpoints, DataService } from '@services/data.service';
import {
  EntitylementTypes,
  PaginatedRequest,
  SnackBarFacade,
  TenantId,
  TenantUtilityTokens,
  displayMessage,
} from '@ra-state';
import * as _ from 'lodash';
import { CommandRequestBuilderService } from '@app/services/command-request-builder.service';
import { CustomerSupportEvents, UserEvents } from '@app/services/command-request.service';
import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TenantEffects {
  getTenantUtilityTokens$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantUtilityTokens),
      switchMap((tenantId) => {
        return this.dataService.getTenantUtilityTokens$(tenantId.value);
      }),
      switchMap((tenantUtilityTokens: TenantUtilityTokens) => {
        // TODO: remove this piece of code when the backend provides the correct combineType
        tenantUtilityTokens.tokenEntitlements.forEach((entitlement) => {
          if (_.isEmpty(entitlement.attributes)) {
            entitlement.attributes = {
              combineType: EntitylementTypes.utilityToken,
            };
          }
        });
        tenantUtilityTokens.disabledTokenEntitlements.forEach((disabledEntitlement) => {
          if (_.isEmpty(disabledEntitlement.attributes)) {
            disabledEntitlement.attributes = {
              combineType: EntitylementTypes.utilityToken,
            };
          }
        });
        return [setTenantUtilityTokens({ payload: tenantUtilityTokens })];
      }),
    );
  });

  getTenantUsers$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantUsers),
      switchMap((payload) => {
        return this.dataService.getTenantUsers$(payload.payload);
      }),
      map((response) => {
        return setTenantUsers({ tenantUsers: response.records });
      }),
    );
  });

  getTenantFilteredUsers$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantFilteredUsers),
      switchMap((payload) => {
        return this.dataService.getTenantUsers$(payload.payload);
      }),
      map((response) => {
        return setTenantFilteredUsers({ selectedTenantUsers: response });
      }),
    );
  });

  getTenantDetails$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantDetails),
      switchMap((tenantId) => {
        return this.dataService.getTenantDetail$(tenantId.value);
      }),
      switchMap((tenantDetails) => {
        return [setTenantDetails({ payload: tenantDetails })];
      }),
    );
  });

  transferOwnership$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(transferOwnership),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new(
            `${ApiEndPoints.Users}${payload.payload.payloadBody.userId}/transfer/${payload.payload.payloadBody.resourceId}`,
            'POST',
            UserEvents.UserRoleAssigned,
          )
          .withBody(payload.payload.payloadBody)
          .withTenantHeader(payload.payload.payloadBody.tenantId)
          .withWaitOn200Ok()
          .withErrorHandler((error: unknown) => {
            const httpError = error as HttpErrorResponse;
            if (httpError.error?.errorCode === 'UserAlreadyAssignedRole') {
              const message =
                payload.payload.payloadBody.tenantId === payload.payload.payloadBody.resourceId
                  ? 'The given user is already the owner for the organization.'
                  : 'The given user is already the owner for the service.';
              this.snackBarFacade.displayMessage({
                message: message,
                type: 'Error',
              });
            }
            return throwError(() => error);
          });
        return combineLatest([this.dataService.commandRequest$(commandRequest), of(payload.payload)]);
      }),
      switchMap(([_res, payload]) => {
        const actions: Action[] = [];
        actions.push(getTenantDetails({ value: payload.payloadBody.tenantId }));
        actions.push(
          getTenantUsers({
            payload: {
              tenantId: payload.payloadBody.tenantId as unknown as TenantId,
            } as PaginatedRequest,
          }),
        );
        const displayMsg = displayMessage({
          payload: {
            message: payload.message,
            type: 'Success',
          },
        });
        actions.push(displayMsg);
        return actions;
      }),
    );
  });

  createAdminInvitation$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(createAdminInvitation),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new(CustomerSupportEndpoints.InviteAdmin, 'POST', CustomerSupportEvents.ServiceRequestResolved)
          .withWaitOn200Ok()
          .asAssistedRequest()
          .withTenantHeader(payload.payload.payloadBody.tenantId)
          .withBody(payload.payload.payloadBody);
        return combineLatest([this.dataService.commandRequest$(commandRequest), of(payload.payload)]);
      }),
      map(([_res, _payload]) => {
        return displayMessage({
          payload: {
            message: _payload.message,
            type: 'Success',
          },
        });
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private snackBarFacade: SnackBarFacade,
    private commandRequestBuilderService: CommandRequestBuilderService,
  ) {}
}
