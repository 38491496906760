import { Action, createReducer, on } from '@ngrx/store';
import { TenantState } from '@ra-state';
import {
  setTenantDetails,
  setTenantFilteredUsers,
  setTenantId,
  setTenantUsers,
  setTenantUtilityTokens,
} from './tenant.actions';

export const initialTenantState: TenantState = {
  tenantId: '',
  tenantUsers: [],
  selectedTenantUsers: {
    currentPage: 1,
    records: [],
    total: 1,
  },
  tenantInfo: {
    description: '',
    emailDomain: '',
    id: '',
    location: '',
    name: '',
    owner: '',
    ownerName: '',
    visibility: '',
    services: [],
    disabledServices: [],
    pendingServices: [],
    entitlements: [],
    tenantStatus: ''
  },
};

const tenantActions = createReducer(
  initialTenantState,
  on(
    setTenantId,
    (state: TenantState, { tenantId }): TenantState => ({
      ...state,
      tenantId,
    }),
  ),
  on(
    setTenantUtilityTokens,
    (state: TenantState, { payload }): TenantState => ({
      ...state,
      tenantUtilityTokens: payload,
    }),
  ),
  on(
    setTenantUsers,
    (state: TenantState, { tenantUsers }): TenantState => ({
      ...state,
      tenantUsers,
    }),
  ),
  on(
    setTenantFilteredUsers,
    (state: TenantState, { selectedTenantUsers }): TenantState => ({
      ...state,
      selectedTenantUsers,
    }),
  ),
  on(
    setTenantDetails,
    (state: TenantState, { payload }): TenantState => ({
      ...state,
      tenantInfo: payload,
    }),
  ),
);

export function tenantStateReducer(state: TenantState, action: Action): any {
  return tenantActions(state, action);
}
