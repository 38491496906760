import {
  AgFilterContext,
  AgFilterModel,
  AgGridEntitlementColumnsFormat,
  ApplicationCard,
  ChipInfo,
  Entitlement,
  EntitlementBadge,
  EntitlementStatus,
  EntitlmentService,
  FilterContext,
  FilterModel,
  FilterType,
  ICatalogInfo,
  ITenantDetail,
  RASymbols,
  TrialCampaignStatus,
  TrialReservationStatus,
  UniversalCredits,
  isPreviewApp,
} from '@ra-state';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { CHIP_COLORS } from '@ra-web-tech-ui-toolkit/components';
import { AppId } from '@rockwell-automation-inc/common-utils';
import * as _ from 'lodash';

export const TOKEN_BALANCE_FRACTION_DIGITS = 3;

export function toFilterModel(agFilterModel: AgFilterModel): FilterModel {
  const filterModel: FilterModel = {};
  Object.keys(agFilterModel).forEach((key) => {
    const agFilterContext: AgFilterContext = agFilterModel[key];
    const filterContext: FilterContext = {
      type: agFilterModel[key].type,
      filterType: agFilterModel[key].filterType,
    };

    switch (agFilterContext.filterType) {
      case 'text':
        filterContext.filter = agFilterModel[key].filter;
        break;

      case 'set':
        filterContext.values = agFilterModel[key].values?.map((value) => {
          if (value === null) {
            return '';
          }
          return value;
        });
        break;

      case 'number':
        filterContext.filter = agFilterModel[key].filter;
        filterContext.filterTo = agFilterModel[key].filterTo;
        break;

      case 'date':
        filterContext.dateFrom = getDateWithMaxOrMinDayTime(`${agFilterContext?.dateFrom}`, agFilterContext?.type);
        filterContext.dateTo = agFilterContext.dateTo
          ? getDateWithMaxDayTime(`${agFilterContext?.dateTo}`)
          : new Date();
        break;

      default:
        break;
    }
    filterModel[key] = filterContext;
  });
  return filterModel;
}

export function getDateWithMaxOrMinDayTime(value: string, filterType?: FilterType): Date {
  const datePart = value.split(' ')[0];
  switch (filterType) {
    case 'lessThanOrEqual':
      return getDateWithMaxDayTime(value);
    case 'greaterThanOrEqual':
      return getDateWithMinDayTime(value);
    case 'inRange':
      return getDateWithMinDayTime(value);
    default:
      return new Date(datePart);
  }
}

export function getDateWithMaxDayTime(value: string): Date {
  const datePart = value.split(' ')[0];
  return new Date(`${datePart}T23:59:59`);
}

export function getDateWithMinDayTime(value: string): Date {
  const datePart = value.split(' ')[0];
  return new Date(`${datePart}T00:00:01`);
}

export function mapUTCDateTimeToLocal(dateTime: string, options?: string): string {
  if (dateIsValid(dateTime)) {
    let defaultOption: DateTimeFormatOptions;
    switch (options) {
      case 'date':
        defaultOption = DateTime.DATE_MED;
        break;
      case 'dateTime':
        defaultOption = DateTime.DATETIME_MED;
        break;
      case 'short':
        defaultOption = DateTime.DATE_SHORT;
        break;
      default:
        defaultOption = DateTime.DATE_FULL;
    }

    return DateTime.fromISO(new Date(dateTime).toISOString()).toLocaleString(defaultOption);
  }
  return '';
}

export function dateIsValid(date: any): any {
  return !Number.isNaN(new Date(date).getTime());
}

export function formatBadgeCopyColumnContent(badge: string): any {
  return EntitlementBadge[badge as unknown as keyof typeof EntitlementBadge];
}

export function mapEntitlementData(params: Entitlement, catalogInfos: Map<string, ICatalogInfo>): any {
  const serviceKind = params?.serviceKind;
  const appName = serviceKind ? getAppNameOrDefault(allApps, serviceKind) : '';
  return {
    header: catalogInfos?.get(params?.catalogNumber)?.name ?? params?.catalogNumber,
    subheader: appName + 'Catalog: ' + params?.catalogNumber,
  };
}

function getAppNameOrDefault(
  allApps: Map<AppId | UniversalCredits, ApplicationCard>,
  serviceKind: AppId | UniversalCredits,
): any {
  return (allApps.get(serviceKind)?.appName ?? serviceKind) + ' | ';
}

export function formatDateCopyColumnContent(date: any): any {
  return mapUTCDateTimeToLocal(date, 'date');
}

export function getProductFamily(serviceKind: AppId | UniversalCredits): string {
  if (serviceKind === 'UniversalCredits') {
    return 'Universal Credits';
  }
  const appData = allApps.get(serviceKind);
  return appData?.appName ?? serviceKind;
}

export function updateServiceKind(filterModel: FilterModel): FilterModel {
  if (filterModel['serviceKind']) {
    filterModel['serviceKind'].values = filterModel['serviceKind']?.values?.map((value) =>
      value === 'UniversalCredits' ? '' : value,
    );
  }
  return filterModel;
}

export function getEntitlementStatus(status: EntitlementStatus): string {
  return status === EntitlementStatus.COMPLETED ? 'Expired' : status;
}

export const applications: ApplicationCard[] = [
  {
    appName: 'FactoryTalk Vault',
    appId: AppId.Vault,
  },
  {
    appName: 'FactoryTalk Remote Access',
    appId: AppId.SecureRemoteAccess,
  },
  {
    appName: 'FactoryTalk Design Studio',
    appId: AppId.IDE,
    isDefaultTrialEnabled: true,
  },
  {
    appName: 'Foo (Integration Sample)',
    appId: AppId.FooService,
  },
  {
    appName: 'FactoryTalk Edge Manager',
    appId: AppId.EaaS,
  },
  {
    appName: 'FT Twin Studio',
    appId: AppId.TwinStudio,
  },
  {
    appName: 'FactoryTalk Optix',
    appId: AppId.FTOptix,
  },
  {
    appName: 'Asset Intelligence',
    appId: AppId.AssetPerformanceMonitor,
  },
  {
    appName: 'FactoryTalk DataMosaix',
    appId: AppId.DataMosaix,
  },
  {
    appName: 'FactoryTalk Energy Manager',
    appId: AppId.EnergyManager,
  },
  {
    appName: 'Batch Performance Analytics',
    appId: AppId.BatchPerformanceAnalytics,
  },
  {
    appName: 'Enterprise FTAC',
    appId: AppId.AssetCenter,
  },
  {
    appName: 'PlantPAx Analytics',
    appId: AppId.PPaxAnalytics,
  },
  {
    appName: 'Converged Data Services',
    appId: AppId.CDS,
  },
];

export const allApps: Map<AppId | 'UniversalCredits', ApplicationCard> = new Map(
  applications.map((app) => [app.appId, app]),
);

export function getAppIds(): Array<string> {
  const filteredApps: (AppId | 'UniversalCredits')[] = Array.from(allApps.values())
    .filter((appConfig) => !isPreviewApp(appConfig.appId))
    .map((appConfig) => appConfig.appId);
  return filteredApps.concat(['UniversalCredits']);
}

export function getTrialsAppIds(): Array<string> {
  const filteredApps: (AppId | 'UniversalCredits')[] = Array.from(allApps.values())
    .filter((appConfig) => !isPreviewApp(appConfig.appId))
    .filter((appConfig) => appConfig.isDefaultTrialEnabled)
    .map((appConfig) => appConfig.appId);
  return filteredApps.concat(['UniversalCredits']);
}

export function getChipInfo(value: string): ChipInfo {
  switch (value) {
    case 'active':
      return { color: CHIP_COLORS.Success, text: TrialReservationStatus.active };
    case 'cancelled':
      return { color: CHIP_COLORS.Offline, text: TrialReservationStatus.cancelled };
    case 'linkexpired':
      return { color: CHIP_COLORS.Offline, text: TrialReservationStatus.linkexpired };
    case 'ended':
      return { color: CHIP_COLORS.Offline, text: TrialCampaignStatus.ended };
    case 'expired':
      return { color: CHIP_COLORS.Offline, text: TrialReservationStatus.expired };
    case 'redeemed':
      return { color: CHIP_COLORS.Information, text: TrialReservationStatus.redeemed };
    case 'unredeemed':
      return { color: CHIP_COLORS.Pending, text: TrialReservationStatus.unredeemed };
    case 'provisioning':
      return { color: CHIP_COLORS.Warning, text: TrialReservationStatus.provisioning };
    default:
      return { color: 'none', text: RASymbols.MINUS };
  }
}

export function formatEntitlementCopyColumnContent(data: AgGridEntitlementColumnsFormat): any {
  return `${data.header} ${data.subheader}`;
}

export function agDateComparator(filterDate: Date, cellValue: string | null): any {
  if (cellValue === null) {
    return -1;
  }
  const cellDate = new Date(cellValue);
  cellDate.setHours(0, 0, 0, 0); // Setting hours as 0 for exact comparision - As filterDate is filterLocalDateAtMidnight (without hours)
  if (filterDate.getTime() === cellDate.getTime()) {
    return 0;
  }
  if (cellDate.getTime() <= filterDate.getTime()) {
    return -1;
  }
  if (cellDate.getTime() >= filterDate.getTime()) {
    return 1;
  }
  return 0;
}

export function getServiceEntitlements(tenantDetails: ITenantDetail): Entitlement[] {
  const mapEntitlements = (services: EntitlmentService[]): Entitlement[] => {
    return (
      services
        ?.flatMap((service) => {
          return service.entitlements.map((entitlement) => ({
            ...entitlement,
            serviceKind: service.kind,
          }));
        })
        ?.filter((entitlement) => !entitlement.isSystemGenerated) || []
    );
  };

  const pendingServicesEntitlements = (tenantDetails.pendingServices || [])
    .flatMap((pendingService) => {
      return pendingService.entitlement
        ? {
            ...pendingService.entitlement,
            serviceKind: pendingService.kind,
            timestamp: 'Pending',
          }
        : [];
    })
    .filter((entitlement) => !entitlement.isSystemGenerated);

  const provisionedServicesEntitlements = mapEntitlements(tenantDetails.services);
  const disabledServiceEntitlements = mapEntitlements(tenantDetails.disabledServices);

  return [...provisionedServicesEntitlements, ...pendingServicesEntitlements, ...disabledServiceEntitlements];
}

export const emailPattern: RegExp =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function modifyFilterForHasCredits(filterModel: FilterModel, colName: string): FilterModel {
  const filterModelClone = _.cloneDeep(filterModel);
  if (filterModelClone[colName]) {
    filterModelClone['hasCredits'] = filterModelClone[colName];
    delete filterModelClone[colName];
  }
  return filterModelClone;
}

export function checkNumericInput(delegateFn, control): any {
  if(delegateFn(control) === null){
    return null;
  } else if(control.value >= 10000000000000000){
    return {insufficientQuantity: true};
  } else {
    return { 'Please provide a positive integer value.': true };
  }
}

export function roundToThreeDecimals(value: number): number {
  const roundingFactor = Math.pow(10, TOKEN_BALANCE_FRACTION_DIGITS);
  return Math.ceil(value * roundingFactor) / roundingFactor;
}

